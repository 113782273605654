@charset "UTF-8";
/*!
 *  dotudash ~ blog
 *
 *  @author   Михаил Драгункин <contact@md.land>
 *  @version  1.0.0
 *
 *  todo add `blog-` prefix to `blog` section blocks.
 */
/* @group dotudash blog */
.blog-container {
  padding-top: 10px;
  padding-bottom: 10px; }

/* @group blog side menu */
.side-menu__header-2 {
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px; }

/* @end */
/* @group list of items `item-list` */
.item-list {
  font-size: 0;
  vertical-align: top;
  padding: 0;
  list-style-type: none;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 5px; }
  .item-list__item, .item-list_type_hierarchical .item-list_type_hierarchical .item-list__item {
    display: inline-block;
    width: 100%;
    padding: 4px 0 4px 5px;
    border-radius: 2px;
    margin-bottom: 1px;
    font-size: 12px;
    color: #777; }
    .item-list__item_active, .item-list__item:hover, .item-list_type_hierarchical .item-list_type_hierarchical .item-list__item_active, .item-list_type_hierarchical .item-list_type_hierarchical .item-list__item:hover {
      color: #444;
      background: #f6f6f6; }
  .item-list__item-icon {
    color: #ccc;
    margin-right: 3px; }
  .item-list__item-extra {
    float: right;
    display: inline-block;
    margin-right: 10px;
    color: #bbb; }
  .item-list_type_hierarchical .item-list__item {
    color: #000; }
  .item-list_type_hierarchical .item-list_type_hierarchical .item-list__item {
    padding-left: 15px; }
  .item-list_type_hierarchical .item-list_type_hierarchical .item-list_type_hierarchical .item-list__item {
    padding-left: 30px; }
  .item-list_type_hierarchical .item-list_type_hierarchical .item-list_type_hierarchical .item-list_type_hierarchical .item-list__item {
    padding-left: 45px; }
  .item-list_type_hierarchical .item-list_type_hierarchical {
    margin-bottom: 0; }

/* @end */
/* @group article-content */
.article {
  padding-bottom: 15px;
  line-height: 16px; }
  .article__header {
    margin: 10px 0;
    line-height: 1.3em; }
  .article__category {
    /* @group themes */
    /* @end */ }
    .article__category {
      /* @group red theme */
      /* @end */ }
      .html_theme_red .article__category {
        color: #ff0000; }
        .html_theme_red .article__category:hover {
          color: #cc0000; }
    .article__category {
      /* @group orange theme */
      /* @end */ }
      .html_theme_orange .article__category {
        color: #ffa500; }
        .html_theme_orange .article__category:hover {
          color: #cc8400; }
    .article__category {
      /* @group purple theme */
      /* @end */ }
      .html_theme_purple .article__category {
        color: #7400bf; }
        .html_theme_purple .article__category:hover {
          color: #55008c; }
    .article__category {
      /* @group green theme */
      /* @end */ }
      .html_theme_green .article__category {
        color: #08bb33; }
        .html_theme_green .article__category:hover {
          color: #068a26; }
    .article__category {
      /* @group java theme */
      /* @end */ }
      .html_theme_java .article__category {
        color: #00aaaa; }
        .html_theme_java .article__category:hover {
          color: #007777; }
    .article__category {
      /* @group lightblue theme */
      /* @end */ }
      .html_theme_lightblue .article__category {
        color: #0070df; }
        .html_theme_lightblue .article__category:hover {
          color: #0056ac; }
    .article__category {
      /* @group black theme */
      /* @end */ }
      .html_theme_black .article__category {
        color: #000000; }
        .html_theme_black .article__category:hover {
          color: black; }
  .article__title {
    color: #444444 !important;
    text-decoration: none; }
    .article__title:hover {
      text-decoration: underline; }
  .article__content {
    line-height: 1.6em; }
    .article__content p {
      margin: 10px 0; }
    .article__content a {
      /* @group themes */
      /* @end */ }
      .article__content a {
        /* @group red theme */
        /* @end */ }
        .html_theme_red .article__content a {
          color: #ff0000; }
          .html_theme_red .article__content a:hover {
            color: #cc0000; }
      .article__content a {
        /* @group orange theme */
        /* @end */ }
        .html_theme_orange .article__content a {
          color: #ffa500; }
          .html_theme_orange .article__content a:hover {
            color: #cc8400; }
      .article__content a {
        /* @group purple theme */
        /* @end */ }
        .html_theme_purple .article__content a {
          color: #7400bf; }
          .html_theme_purple .article__content a:hover {
            color: #55008c; }
      .article__content a {
        /* @group green theme */
        /* @end */ }
        .html_theme_green .article__content a {
          color: #08bb33; }
          .html_theme_green .article__content a:hover {
            color: #068a26; }
      .article__content a {
        /* @group java theme */
        /* @end */ }
        .html_theme_java .article__content a {
          color: #00aaaa; }
          .html_theme_java .article__content a:hover {
            color: #007777; }
      .article__content a {
        /* @group lightblue theme */
        /* @end */ }
        .html_theme_lightblue .article__content a {
          color: #0070df; }
          .html_theme_lightblue .article__content a:hover {
            color: #0056ac; }
      .article__content a {
        /* @group black theme */
        /* @end */ }
        .html_theme_black .article__content a {
          color: #000000; }
          .html_theme_black .article__content a:hover {
            color: black; }
    .article__content ul {
      padding-left: 20px; }
    .article__content hr {
      width: 100%;
      margin: 5px 0;
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .article__continue-read-link {
    /* @group themes */
    /* @end */ }
    .article__continue-read-link {
      /* @group red theme */
      /* @end */ }
      .html_theme_red .article__continue-read-link {
        color: #ff0000; }
        .html_theme_red .article__continue-read-link:hover {
          color: #cc0000; }
    .article__continue-read-link {
      /* @group orange theme */
      /* @end */ }
      .html_theme_orange .article__continue-read-link {
        color: #ffa500; }
        .html_theme_orange .article__continue-read-link:hover {
          color: #cc8400; }
    .article__continue-read-link {
      /* @group purple theme */
      /* @end */ }
      .html_theme_purple .article__continue-read-link {
        color: #7400bf; }
        .html_theme_purple .article__continue-read-link:hover {
          color: #55008c; }
    .article__continue-read-link {
      /* @group green theme */
      /* @end */ }
      .html_theme_green .article__continue-read-link {
        color: #08bb33; }
        .html_theme_green .article__continue-read-link:hover {
          color: #068a26; }
    .article__continue-read-link {
      /* @group java theme */
      /* @end */ }
      .html_theme_java .article__continue-read-link {
        color: #00aaaa; }
        .html_theme_java .article__continue-read-link:hover {
          color: #007777; }
    .article__continue-read-link {
      /* @group lightblue theme */
      /* @end */ }
      .html_theme_lightblue .article__continue-read-link {
        color: #0070df; }
        .html_theme_lightblue .article__continue-read-link:hover {
          color: #0056ac; }
    .article__continue-read-link {
      /* @group black theme */
      /* @end */ }
      .html_theme_black .article__continue-read-link {
        color: #000000; }
        .html_theme_black .article__continue-read-link:hover {
          color: black; }
  .article + .article {
    padding-top: 15px;
    border-top: 1px solid #efefef; }

.empty-section {
  text-align: center; }
  .empty-section__header {
    font-weight: normal;
    font-size: 2rem;
    margin-top: 50px;
    color: #888; }

/* @end */
/* @end */
