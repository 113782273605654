@charset "UTF-8";

/*!
 *  dotudash ~ blog
 *
 *  @author   Михаил Драгункин <contact@md.land>
 *  @version  1.0.0
 *
 *  todo add `blog-` prefix to `blog` section blocks.
 */


@import '../common/mixins/fight-space';
@import '../common/mixins/theme-color';
@import '../common/mixins/padding';


/* @group dotudash blog */
.blog-container {
  @include vertical-padding(10px);
}

/* @group blog side menu */
.side-menu {
  // contains few blocks (eg category list, tag list, and etc)
  &__header-2 {
    font-weight: normal;
    margin: {
      top: 10px;
      bottom: 10px;
    }
  }
}
/* @end */

/* @group list of items `item-list` */
.item-list {
  // warning: related to element list (`ul`, `ol`), not to list element (`li`, and etc)
  @include fight-space;
  padding: 0;

  $self: &;

  list-style-type: none;

  margin: {
    left: 0;
    right: 0;
    top: 0;
    bottom: 5px;
  }

  &__item, &_type_hierarchical &_type_hierarchical &__item {
    display: inline-block;
    width: 100%;
    padding: 4px 0 4px 5px;
    border-radius: 2px;

    margin-bottom: 1px; // prevents background collapse between active & hovered elements

    font-size: 12px; // 1rem
    color: #777;


    &_active, &:hover {
      color: #444;
      background: #f6f6f6;
    }
  }

  &__item-icon {
    color: #ccc;
    margin-right: 3px;
  }

  &__item-name {}

  &__item-extra {
    // todo decide to add clearfix
    float: right;
    display: inline-block;
    margin-right: 10px;
    color: #bbb;
  }

  &_type_hierarchical {
    // @group top level list
    & #{$self}__item {
      color: #000;
    }
    // @end

    $selector: &;

    @for $i from 1 through 3 {
      #{$selector} #{$self}__item {
        padding-left: 15px * $i;
      }

      $selector: #{$selector} &;
    }

    // @group nested list
    & & {
      margin-bottom: 0;
    }
    // @end
  }
}
/* @end */

/* @group article-content */
.article {
  padding-bottom: 15px;
  line-height: 16px;

  &__header {
    margin: 10px 0;
    line-height: 1.3em;
  }

  &__category {
    // todo u know what
    @include theme-color;
  }

  &__separator {}

  &__title {
    color: rgb(68, 68, 68) !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    //@include theme-color(&);
  }

  &__content {
    line-height: 1.6em;

    p { // dirty
      // Ad hoc: markdown render creates this element
      margin: 10px 0;
    }

    a {
      @include theme-color;
    }

    ul {
      padding-left: 20px;
    }

    hr {
      width: 100%;
      margin: 5px 0;

      border: none;
      border-top: 1px solid rgba(0, 0, 0, .1);
      border-bottom: 1px solid rgba(255, 255, 255, .1);
    }
  }

  &__continue-read-link {
    @include theme-color;
  }

  + .article {
    //margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #efefef;
  }
}

.empty-section {
  text-align: center;

  &__header {
    font-weight: normal;

    font-size: 2rem;
    margin-top: 50px;
    color: #888;
  }
}
/* @end */

/* @end */
